
import { Vue, Component, Watch } from 'vue-property-decorator';
import { ContaGerencialService} from '@/core/services/financeiro';
import { ContaGerencial} from '@/core/models/financeiro';

@Component
export default class ListaContaGerencial extends Vue {

    public service = new ContaGerencialService();
    public item = new ContaGerencial();

    public localBuscaRapida: string = "";
    public titulo: string = 'Conta Gerencial';
    public subTitulo: string = 'Conta gerencial cadastrados no Sistema';
    public temBuscaRapida: boolean = true;
    public icone: string = 'mdi-cash';
    public pai:string='';

    loading: boolean = false;
    dialogCadastro: boolean = false;

    dialog:boolean =  false;
    valid: boolean =  true;
    search: string = '';
    nomePai:string = '';
    editedIndex: number = -1;
    contaGerencialPaiId:string='';

    tree:any[] = [];
    open: any[0] = [];
    active:any[] = [];
    lista:any[] = [];
    options: any = {
    itemsPerPage: -1
    };

    breadCrumbLinks: any[] = [
    { text: 'Home', href: '/financeiro/painel/kpi',},
    { text: 'Arvore de Conta Gerencial', disabled: true, href: '/financeiro/painel/despesas'}
  ]

  filter : {
    id: number,
  } = {
    id:0 ,
  }
   sheet:boolean = false;

   Consultar(){
    if(this.filter.id > 0){

    this.options.page = 1;
    
    const { page, itemsPerPage, sortBy, sortDesc, search, columns } = this.options;
    this.loading = true
    this.service.ListarComFiltro(page,itemsPerPage,sortBy,sortDesc,search,columns,this.filter, '').then(
    
    (res) => { 
      let treeAux = [{ id: -1, codigoDescricao: 'RAIZ',children: []}];
      treeAux[0].children = res.data.items;
      this.tree = treeAux;  

      this.totalLista = res.data.count;
      this.loading = false; 
     },
      (err) => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        }
      }); 
    }else{
      this.Listar();
    }
    this.sheet = false;
  }

  ResetFiltro(){
    this.filter = {id:0}
  }


  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];

  $refs!: {
    form: HTMLFormElement
  }

  @Watch('item') 
  Item(){
    if (this.$refs.form) {
      this.$refs.form.resetValidation();
    }
  }

    headers: any[] = [
        { text: '', value: 'actions', sortable: false },
        { text: 'Código', value: 'id' },
        { text: 'Nome', value: 'nome' }
    ];
 
    tipos:any[]= [
        { id: 'R', name: 'Receita'},
        { id: 'D', name: 'Despesa'}
    ]

    totalLista: number = 0;
 

    abrirDialog(item = new ContaGerencial){       
        this.item = new ContaGerencial;
        this.dialog = true;
    }
    

    Salvar() {
       if (this.$refs.form.validate()) {
        let modelo = this.item;
        this.service.Salvar(modelo)
            .then(
                res => {
                    this.$swal('Aviso', 'Operação realizada com sucesso!', res.status == 201 || res.status == 200 ? 'success' : 'warning');
                    this.$emit('salvou');
                     if (res.status == 201 || res.status == 200){
                          this.Listar();
                      }
               }, 
                (err) => {
                if (!err.response) {
                  this.$swal("Aviso", "Não foi possível acessar a API", "error");
                } else if (err.response.status == 403) {
                  this.$swal("Aviso", err.response.data.message, "warning" );
                } else {
                  this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
                }
              }); 
                
        this.close();
      }
    }

    close(){
        this.dialog = false;
    }

    addItem(item: ContaGerencial) {
        let pai = Object.assign({}, this.lista.find(x => x.id === this.contaGerencialPaiId[0]), item );

        this.item = new ContaGerencial();
        this.editedIndex = -1
        this.item.codigo = ''; 
        this.item.contaGerencialPaiId = pai.id === -1 ? null : pai.id;
        this.item.ativo= true;
        this.nomePai = pai.codigoDescricao;
        this.dialog = true;
    }
                
    items: ContaGerencial[] = [];
    total = 0;

    Listar(){
        this.loading = true;

      this.service.TreeView().then(
          res => { let treeAux = [{ id: -1, codigoDescricao: 'RAIZ', children: []}];
          treeAux[0].children = res.data;
          this.tree = treeAux;
            this.loading = false;
          },
          (err) => {
            if (!err.response) {
              this.$swal("Aviso", "Não foi possível acessar a API", "error");
            } else if (err.response.status == 403) {
              this.$swal("Aviso", err.response.data.message, "warning" );
            } else {
              this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
            }
          }); 
        
        this.service.ListarTudo().then(
          res => { let aux = { id: -1, codigoDescricao: 'RAIZ' };
              this.lista = res.data.items;
              this.lista.unshift(aux);
            },
          (err) => {
            if (!err.response) {
              this.$swal("Aviso", "Não foi possível acessar a API", "error");
            } else if (err.response.status == 403) {
              this.$swal("Aviso", err.response.data.message, "warning" );
            } else {
              this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
            }
          }); 
        }
        
    Excluir(item: ContaGerencial){
        this.$swal({
        title: 'Atenção!',
        text: 'Tem certeza que deseja excluir o registro atual?',
        icon: "question",
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        showCloseButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return this.service.Excluir(item.id)
            .then(res => {
                if (res.status == 200){
                    return res;
                }
            }, 
            (err) => {
                if (!err.response) {
                  this.$swal("Aviso", "Não foi possível acessar a API", "error");
                } else if (err.response.status == 403) {
                  this.$swal("Aviso", err.response.data.message, "warning" );
                } else {
                  this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
                }
              }); 
          },
        // @ts-ignore: Unreachable code error
        allowOutsideClick: () => !this.$swal.isLoading()
        })
        .then((result) => {
            if(result.value) {
                this.$swal('Aviso', result.value.data, result.value.status == 200 ? 'success' : 'warning');
                this.Listar();
            }
        })
    } 
    
    Editar(item = new ContaGerencial){
      this.editedIndex = 1;
      let pai = this.lista.find(x => x.id == item.contaGerencialPaiId);
      this.item = new ContaGerencial();
      this.nomePai = pai ? pai.codigoDescricao : 'RAIZ';
      this.item = item;
      this.dialog = true;
    }

    mounted() {
      this.Listar();
    }
}

